import { Button, Divider } from "@mantine/core";
import { useState } from "react";
import { PageLayout } from "../../components/layout";
import UserForm from "../../components/user/user-form";
import UserList from "../../components/user/user-list";

const Users = () => {
  const breadcrumbs = useBreadcrumb();
  const [open, setOpen] = useState<boolean>(false);
  return (
    <PageLayout title="Системийн хэрэглэгчид" breadcrumb={breadcrumbs} extra={[<Button onClick={() => setOpen(true)}>Хэрэглэгч нэмэх</Button>]}>
      <Divider mb="xl" />
      <UserForm isOpen={open} onCancel={() => setOpen(false)} />
      <UserList />
    </PageLayout>
  );
};

export default Users;

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгчдийн жагсаалт",
  },
];
