import { ActionIcon, Badge, Button, Center, Divider, Modal, Text, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconEye } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { ebarimtApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import RenderText from "../../../components/ui/render-text";
import { BARIMT_STATUS } from "../../../constants/ebarimt";
import { Message } from "../../../utils/message";

const Receipts = () => {
  const breadcrumbs = useBreadcrumb();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [query, setQuery] = useState("");
  const [queryDebounced] = useDebouncedValue(query, 400);
  const ref = useRef<ITableRef>(null);
  const [action, setAction] = useState<any>();

  const columns = useHeader({
    onClick: (key, record) => {
      switch (key) {
        case "see": {
          setAction(["open", record as string]);
          break;
        }
      }
    },
  });

  const handleSend = async () => {
    try {
      await ebarimtApi.send();
      Message.success("Амжилттай илгээлээ");
    } catch (error: any) {
      Message.error(error?.message?.message);
    }
  };

  return (
    <PageLayout
      title="Баримтын жагсаалт"
      subTitle="Dehub B2B платформ дээр үүссэн И-баримтуудын жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[<Button onClick={() => handleSend()}>Баримт илгээх</Button>]}>
      <Divider mb="lg" />
      <Table
        ref={ref}
        name="partner-management-user.list"
        filters={{ invoiceId: queryDebounced }}
        columns={columns}
        loadData={(data) => ebarimtApi.list(data!)}
      />
      <Modal opened={action?.[0] === "open"} onClose={() => setAction(null)} title="Төлбөрийн баримт" size="90%">
        {action?.[1] ? (
          <iframe src={`${action[1]}`} width="100%" height="700px" style={{ border: "none" }} title="ebarimt" />
        ) : (
          <Center sx={(theme) => ({ padding: theme.spacing.xl })}>
            <Text>Танд одоогоор харах баримт байхгүй байна</Text>
          </Center>
        )}
      </Modal>
    </PageLayout>
  );
};

export default Receipts;

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Баримтын жагсаалт",
  },
];

interface BarimtStatus {
  barimtStatus: keyof typeof BARIMT_STATUS;
}

const useHeader = ({ onClick }: { onClick: (key: string, record: any) => void }): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => <RenderText text={index + 1} />,
  },
  {
    title: "Үйлдэл",
    render: (record) => (
      <Tooltip label="Баримт харах">
        <ActionIcon variant="light" onClick={() => onClick("see", record.pdfUrl)}>
          <IconEye />
        </ActionIcon>
      </Tooltip>
    ),
  },
  {
    title: "ДТТД",
    render: (record) => <RenderText text={record.ebarimtId ?? "-"} />,
  },
  {
    title: "Сугалаа",
    render: (record) => <RenderText text={record?.type === "B2B_RECEIPT" ? "-" : record.lottery} />,
  },
  {
    title: "Баримтын төлөв",
    render: (record: BarimtStatus) => {
      const status = BARIMT_STATUS[record.barimtStatus];
      return (
        <Badge color={BARIMT_STATUS[record.barimtStatus].color} variant="light" radius="xs">
          {status?.name ?? "-"}
        </Badge>
      );
    },
  },
  {
    title: "Баримтын төрөл",
    render: (record) => <RenderText text={record?.type === "B2B_RECEIPT" ? "Байгууллага" : "Иргэн"} />,
  },
  {
    title: "Регистр",
    render: (record) => <RenderText text={record?.type === "B2B_RECEIPT" ? record.customerRegister : record.consumerNo} />,
  },
  {
    title: "Хүлээн авсан",
    render: (record) => <RenderText text={record?.type === "B2B_RECEIPT" ? record.customerName : record.consumerName} />,
  },
  {
    title: "Баримт илгээсэн",
    render: (record) => <RenderText text={record.barimtReceiveEmail ?? "-"} />,
  },
];
