import { Box, Divider, Grid, LoadingOverlay, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBuilding, IconMap, IconUser } from "@tabler/icons-react";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { PartnerApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { PartnerManagementPartnerMainForm } from "../../../components/partner/main-form";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";

export function PartnerManagementPartnerNew() {
  const { classes } = useStyles();
  const params = useParams();
  const navigate = useNavigate();
  const breadcrumbs = useBreadcrumb();
  const [tabIndex] = React.useState("1");
  const [loading, setLoading] = React.useState<boolean>(false);

  const onSubmit = async (data: any, type: boolean) => {
    setLoading(true);

    try {
      const res = await PartnerApi.create({
        type: data.type,
        partnerCategory: data.partnerCategory,
        classification: data.classification,
        regNumber: data.regNumber.toString(),
        businessName: data.businessName,
        businessNameEng: data.businessNameEng,
        legalEntityType: data.legalEntityType,
        country: data.country,
        equityType: data.equityType,
        web: data.web,
        email: data.email,
        email2: data.email2,
        phone: data.phone.toString(),
        phone2: data.phone2.toString(),
        fbLink: data.fbLink,
        isContinue: type,
        isActive: data.isActive === "true",
        contractDate: data.contractDate,
        serviceStartDate: data.serviceStartDate,
      });

      if (type) {
        navigate(`/partner-management/partner/${params.type}/edit/${res.id}/2`);
        Message.success("Партнерийг амжилттай үүсгэсэн.");
      } else {
        navigate("/partner-management/partner");
        Message.success("Партнерийн амжилттай шинээр бүртгэсэн.");
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <PageLayout title="Шинэ партнер бүртгэх" subTitle="Партнерийн мэдээллийг бүртгэх" breadcrumb={breadcrumbs}>
      <Divider mb="lg" />
      <Grid gutter="md">
        <Grid.Col span={12}>
          <Box px={20} py={16} className={classes.gridBox}>
            <Tabs h="100%" variant="pills" defaultValue="1">
              <LoadingOverlay visible={loading} />
              <Tabs.List>
                <Tabs.Tab leftSection={<IconBuilding color={tabIndex === "1" ? "white" : "gray"} />} value="1">
                  <Text c={tabIndex === "1" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
                </Tabs.Tab>
                <Tabs.Tab leftSection={<IconMap color={tabIndex === "2" ? "white" : "gray"} />} value="2" disabled>
                  <Text c={tabIndex === "2" ? "" : "gray"}>Хаягийн мэдээлэл</Text>
                </Tabs.Tab>
                <Tabs.Tab value="3" leftSection={<IconUser color={tabIndex === "3" ? "white" : "gray"} />} disabled>
                  <Text c={tabIndex === "3" ? "" : "gray"}>Админ хэрэглэгч</Text>
                </Tabs.Tab>
                <Divider mt="md" mb="md" />
                <Tabs.Panel value="1">
                  <PartnerManagementPartnerMainForm onSubmit={onSubmit} action={["create"]} />
                </Tabs.Panel>
              </Tabs.List>
            </Tabs>
          </Box>
        </Grid.Col>
      </Grid>
    </PageLayout>
  );
}

const useStyles = createStyles((theme) => ({
  gridBox: {
    height: "100%",
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },
}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/partner-management/partner",
    label: "Партнер жагсаалт",
  },
  {
    label: "Шинэ партнер бүртгэх",
  },
];
