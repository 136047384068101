import { Modal } from "@mantine/core";

interface UserFormProps {
  isOpen: boolean;
  onCancel: () => void;
}

const UserForm = ({ isOpen, onCancel }: UserFormProps) => {
  return <Modal title="Хэрэглэгч бүртгэх" opened={isOpen} onClose={onCancel} size="60rem"></Modal>;
};

export default UserForm;
