import { Divider } from "@mantine/core";
import { PageLayout } from "../../components/layout";

const UserRoles = () => {
  const breadcrumbs = useBreadcrumb();
  return (
    <PageLayout title="Хэрэглэгчийн роль" breadcrumb={breadcrumbs}>
      <Divider />
    </PageLayout>
  );
};

export default UserRoles;

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгчдийн эрх",
  },
];
