import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async (data: any) => {
  return httpRequest.get("/prt/back_office/partner", data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/prt/back_office/partner/${id}`);
};

export const roleSelect = async (data: any) => {
  return httpRequest.get("/prt/role/select", data);
};

export const uploadLogo = async (id: string, data: any) => {
  return httpRequest.put(`/prt/partner/${id}/logo`, data);
};

export const create = async (data: IFormDataCreate) => {
  return httpRequest.post("/prt/back_office/partner", data);
};

export const updateInfo = async (id: string, data: IFormDataCreate) => {
  return httpRequest.put(`/prt/back_office/partner/${id}`, data);
};

export const address = async (id: string, data: IFormDataAddress) => {
  return httpRequest.put(`/prt/back_office/partner/${id}/address`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/prt/back_office/partner/${id}`);
};

export const admin_create = async (id: string, data: any) => {
  return httpRequest.post(`/prt/back_office/partner/${id}/admin`, data);
};

export const admin_update = async (partnerId: string, adminid: string, data: any) => {
  return httpRequest.put(`/prt/back_office/partner/${partnerId}/admin/${adminid}`, data);
};

export const adminList = async (data: any) => {
  return httpRequest.get("/prt/back_office/partner/admin_list", data);
};

export const adminActive = async (partnerId: string, adminId: string) => {
  return httpRequest.put(`/prt/back_office/partner/${partnerId}/admin/${adminId}/activate`);
};

export const adminInActive = async (partnerId: string, adminId: string) => {
  return httpRequest.put(`/prt/back_office/partner/${partnerId}/admin/${adminId}/deactivate`);
};

export const send = async (id: string) => {
  return httpRequest.put(`/prt/back_office/partner/admin/${id}/send`);
};

export const removeAdmin = async (id: string) => {
  return httpRequest.del(`/prt/back_office/partner/admin/${id}`);
};

export const contract = async (id: string) => {
  return httpRequest.put(`/prt/back_office/partner/${id}/contract`);
};

type IFormDataAddress = {
  province: string | undefined;
  district: string | undefined;
  khoroo: string | undefined;
  khotkhonBair: string | undefined;
  khoroolol: string | undefined;
  khashaaDavkhar: string | undefined;
  khaalgaDugaar: string | undefined;
  businessAddress: string | undefined;
  locationLat: number | undefined;
  locationLng: number | undefined;
  isContinue: boolean;
};

type IFormDataCreate = {
  type: string | undefined;
  partnerCategory: string | undefined;
  classification: string | undefined;
  regNumber: number | undefined | string;
  businessName: string | undefined;
  businessNameEng: string | undefined;
  legalEntityType: string | undefined;
  country: string | undefined;
  equityType: string | undefined;
  web: string | undefined;
  email: string | undefined;
  email2: string | undefined;
  phone: string | undefined;
  phone2: string | undefined;
  fbLink: string | undefined;
  isContinue: boolean;
  isActive: boolean;
  contractDate: string;
  serviceStartDate: string;
};
