import { Avatar, Badge, CloseButton, Divider, Text } from "@mantine/core";
import useSWR from "swr";
import { userApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { FormLayout } from "../layout";
import { ColumnType, Table } from "../table";

export function PartnerManagementUserForm({ action, onCancel }: { action: any; onCancel: () => void }) {
  const { data } = useSWR(action[1]?.id ? `/prt/back_office/user/business/${action[1]?.id}` : null, async () => {
    if (!action[1]?.id) return null;
    return await userApi.get(action[1]?.id);
  });

  const columns = useHeader();

  return (
    <FormLayout
      title="Бизнесийн мэдээлэл"
      subTitle={`${action[1]?.lastName ? action[1]?.lastName : ""} ${action[1]?.firstName ? action[1]?.firstName : ""}-ийн хариуцдаг бизнесүүдийн мэдээлэл`}
      my={0}
      extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <Divider mb={5} />
      <Table name="partner-management-user-business-detail.list" columns={columns} filters={{}} dataSource={(data || []).businesses} pagination={false} />
    </FormLayout>
  );
}

const useHeader = (): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Лого",
    sorter: true,
    dataIndex: "logo",
    render: (record) => {
      return <Avatar src={record.logo !== null ? record.logo : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "Партнер",
    sorter: true,
    dataIndex: "username",
    render: (record) => {
      return (
        <>
          <Text size="sm" fw={500} c="#44566C">
            {record?.partnerName || "-"}, {record?.regNumber || "-"}
          </Text>
        </>
      );
    },
  },
  {
    title: "Бизнесийн",
    sorter: true,
    dataIndex: "username",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.refCode || "-"}, {record?.profileName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эрх",
    sorter: true,
    dataIndex: "username",
    render: (record) => {
      return (
        <>
          {(record.roles || []).map((c: any, index: number) => {
            return (
              <Badge
                key={index}
                size="md"
                radius="sm"
                variant="outline"
                ml={5}
                style={{
                  background: "white",
                  borderColor: "#44566C",
                  color: "#44566C",
                }}>
                {c.name || "-"}
              </Badge>
            );
          })}
        </>
      );
    },
  },
];
