import { Badge, MantineColor } from "@mantine/core";

interface ITag {
  color: MantineColor;
  name: string;
}

function Tag({ color, name }: ITag): JSX.Element {
  const badgeProps = color.startsWith("#") ? { style: { border: `${color} 1px solid`, color }, color: undefined } : { color };

  return (
    <Badge {...badgeProps} variant="outline" size="md" radius="sm">
      {name}
    </Badge>
  );
}

export { Tag };
