import { Avatar, Badge, Button, Divider, Drawer, Flex, Grid, Group, Input, Text, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconInfoSquare, IconPhoto, IconTextPlus } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { merchantApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IMerchant } from "../../interfaces/IMerchant";
import { IReference } from "../../models/General";
import { dateFormat } from "../../utils/date";
import { PartnerManagementBankAccountForm } from "../bank-account/form";
import { ColumnType, ITableRef, Table } from "../table";
import { MerchantForm } from "./form";

export function PartnerManagementMerchantList({ action, onSuccess }: { action: string[]; onSuccess: () => void }) {
  const ref = React.useRef<ITableRef>(null);
  const [account, setAccount] = React.useState<string[]>([]);
  const { qpayMccCodes, zipCodes } = useSelector((state: { general: IGeneral }) => state.general);
  const [regNumber, setRegNumber] = React.useState("");
  const [debouncedRegNumber] = useDebouncedValue(regNumber, 600);

  const columns = useHeader({
    zipCodes,
    qpayMccCodes,
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          break;
        }
        case "account": {
          setAccount(["ADD", item as any]);
          break;
        }
      }
    },
  });

  const reload = () => {
    ref.current?.reload();
  };

  const onCancel = () => {
    setAccount([]);
  };

  return (
    <Flex direction={"column"} gap="md">
      <Grid mb="xs">
        <Grid.Col span={{ xs: 12, sm: 12, md: 6, lg: 3 }}>
          <Input
            value={regNumber}
            leftSectionWidth={80}
            placeholder="хайх"
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            leftSection={
              <Group w={"calc(100% - 20px)"} align="center">
                <Text size="sm" fw={450} c="#44566C">
                  {"ТТД"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setRegNumber(e?.currentTarget?.value);
            }}
            minLength={7}
            maxLength={7}
          />
        </Grid.Col>
      </Grid>
      <Divider color="gray" mt={10} mb={10} />
      <Table
        ref={ref}
        name="partner-management-merchant.list"
        columns={columns}
        filters={{ regNumber: debouncedRegNumber, businessName: "" }}
        loadData={(data) => merchantApi.list(data!)}
      />
      <Drawer withCloseButton={false} title={false} position="right" padding={"lg"} size={"70%"} opened={action[0] === "ADD"} onClose={() => onSuccess()}>
        <MerchantForm action={action} onSuccess={onSuccess} reload={reload} />
      </Drawer>
      <Drawer withCloseButton={false} title={false} position="right" padding={"lg"} size={"70%"} opened={account[0] === "ADD"} onClose={() => setAccount([])}>
        <PartnerManagementBankAccountForm account={account} onSuccess={onSuccess} reload={reload} onCancel={onCancel} />
      </Drawer>
    </Flex>
  );
}

const useHeader = ({
  onClick,
  zipCodes,
  qpayMccCodes,
}: {
  onClick: (key: string, record: IMerchant) => void;
  zipCodes: IReference[];
  qpayMccCodes: IReference[];
}): ColumnType<IMerchant>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Үйлдэл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
          <Tooltip label="Данс нэмэх" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("account", record)}>
              <IconTextPlus />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "Зураг",
    dataIndex: "logo",
    render: (record) => {
      return (
        <Avatar src={`${record.logo}`} alt="" size={40}>
          <IconPhoto />
        </Avatar>
      );
    },
  },
  {
    title: "Мерчант ID",
    sorter: true,
    dataIndex: "qpayMerchantId",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantId || "-"}
        </Text>
      );
    },
  },
  {
    title: "Регистр",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.businessName || "-"}
        </Text>
      );
    },
  },
  {
    title: "MCC_Code",
    sorter: true,
    dataIndex: "qpayMerchantMccCode",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {(qpayMccCodes || []).find((c: any) => c?.code === record?.qpayMerchantMccCode)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эзний регистр",
    sorter: true,
    dataIndex: "qpayMerchantOwnerRegNum",
    render: (record) => {
      return (
        <Badge variant="outline" size="md" radius="sm" color="blue">
          {record?.qpayMerchantOwnerRegNum || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Эзний овог",
    sorter: true,
    dataIndex: "qpayMerchantOwnerLastName",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantOwnerLastName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эзний нэр",
    sorter: true,
    dataIndex: "qpayMerchantOwnerFirstName",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantOwnerFirstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Өргөрөг",
    sorter: true,
    dataIndex: "qpayMerchantLat",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantLat || "-"}
        </Text>
      );
    },
  },
  {
    title: "Уртраг",
    sorter: true,
    dataIndex: "qpayMerchantLng",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantLng || "-"}
        </Text>
      );
    },
  },
  {
    title: "Аймаг, нийслэл",
    sorter: true,
    dataIndex: "qpayMerchantProvince",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {(zipCodes || []).find((c) => c.code === record.qpayMerchantProvince)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Сум, дүүрэг",
    sorter: true,
    dataIndex: "qpayMerchantDistrict",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {(zipCodes || []).find((c) => c.code === record.qpayMerchantDistrict)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Хаяг",
    sorter: true,
    dataIndex: "qpayMerchantAddress",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantAddress || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "qpayMerchantPhone",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantPhone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Имэйл",
    sorter: true,
    dataIndex: "qpayMerchantEmail",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantEmail || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн ажилтан",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {record?.qpayMerchantRegUser?.lastName[0] || "-"}.{record?.qpayMerchantRegUser?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" c="#44566C" w="max-content">
          {dateFormat(record?.createdAt)}
        </Text>
      );
    },
  },
];
