import { Avatar, Box, Checkbox, Divider, Flex, Grid, Group, Input, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconPhoto } from "@tabler/icons-react";
import React from "react";
import { bankAccountApi } from "../../apis";
import { IBankAccount } from "../../interfaces/IBankAccount";
import { ColumnType, Table } from "../table";

export function PartnerManagementBankAccountList() {
  const [regNumber, setRegNumber] = React.useState("");
  const [debouncedRegNumber] = useDebouncedValue(regNumber, 600);

  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          break;
        }
      }
    },
  });

  return (
    <Box>
      <Grid mb="xs">
        <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
          <Input
            value={regNumber}
            leftSectionWidth={80}
            placeholder="хайх"
            style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
            leftSection={
              <Group w={"calc(100% - 20px)"} align="center">
                <Text size="sm" fw={450} c="#44566C">
                  {"ТТД"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            onChange={(e) => {
              setRegNumber(e?.currentTarget?.value);
            }}
            minLength={7}
            maxLength={7}
          />
        </Grid.Col>
      </Grid>
      <Divider color="gray" my={20} />
      <Table
        name="partner-management-bank-account.list"
        columns={columns}
        filters={{ regNumber: debouncedRegNumber, businessId: "", partnerId: "" }}
        loadData={(data) => bankAccountApi.list(data!)}
      />
    </Box>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: IBankAccount) => void }): ColumnType<IBankAccount>[] => [
  {
    title: "#",
    render: (_r, index) => (
      <Text size="sm" c="#44566C">
        {index + 1}
      </Text>
    ),
    width: "30px",
  },
  {
    title: "Зураг",
    dataIndex: "icon",
    render: (record) => {
      return (
        <Avatar src={`${record.icon}`} alt="" size={40}>
          <IconPhoto />
        </Avatar>
      );
    },
  },
  {
    title: "Мерчант ID",
    sorter: true,
    dataIndex: "qpayMerchantId",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.qpayMerchantId || "-"}
        </Text>
      );
    },
  },
  {
    title: "Регистр",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.business?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Box>
          <Text size="sm" fw={500} c="#141517" w="max-content">
            {record?.business?.partner?.businessName || "-"}
          </Text>
          <Text size="sm" fw={500} c="#44566C" w="max-content">
            {record?.business?.partner?.refCode || "-"}
          </Text>
        </Box>
      );
    },
  },
  {
    title: "Бизнес нэр",
    sorter: true,
    dataIndex: "profileName",
    width: 150,
    render: (record) => {
      return (
        <Box>
          <Text size="sm" fw={500} c="#141517" w="max-content">
            {record?.business?.profileName || "-"}
          </Text>
          <Text size="sm" fw={500} c="#44566C" w="max-content">
            {record?.business?.refCode || "-"}
          </Text>
        </Box>
      );
    },
  },
  {
    title: "Банкны нэр",
    sorter: true,
    dataIndex: "bankName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" w="max-content">
          {record?.bankName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "number",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" w="max-content">
          {record?.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record.currency || "-"}
        </Text>
      );
    },
  },
  {
    title: "Үндсэн эсэх",
    sorter: true,
    dataIndex: "shortName",
    render: (record) => (
      <Flex gap={8} align="center">
        <Checkbox checked={record.isDefault} onChange={() => {}} />
        <Text size="sm" c="#44566C">
          {record.isDefault ? "Тийм" : "Үгүй"}
        </Text>
      </Flex>
    ),
  },
  {
    title: "Товч нэр",
    sorter: true,
    dataIndex: "shortName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record.shortName || "-"}
        </Text>
      );
    },
  },
];
