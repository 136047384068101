import { AppShell, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { NavLink, useLocation } from "react-router-dom";
import { navigationDataMappedByLink, navigationDataMappedByParentLink } from "../../constants/navigation";

export function Navigation() {
  const location = useLocation();
  const currentSection = location.pathname.split("/")[1];
  const currentNavigation = navigationDataMappedByLink[`/${currentSection}`] || {};
  const navigation = navigationDataMappedByParentLink[`/${currentSection}`] || [];

  return (
    <AppShell.Navbar style={{ padding: "1rem" }}>
      {navigation.map((item, index) => (
        <NavItem item={item} key={index} color={currentNavigation.color} />
      ))}
    </AppShell.Navbar>
  );
}

type INavItem = {
  title: string;
  icon: any;
  color: string;
  link: string;
  disabled?: boolean;
};

function NavItem({ item, color }: { item: INavItem; color: string }) {
  const { classes, cx } = useStyles({ color });

  return (
    <NavLink className={cx(classes.item, item.disabled && "disabled")} to={!item.disabled ? item.link : "#"}>
      <item.icon className={classes.icon} size={40} />
      <Text size="xs">{item.title}</Text>
    </NavLink>
  );
}

const useStyles = createStyles((theme, { color }: { color: string }) => {
  const iconRef = "icon";

  return {
    item: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: 10,
      marginBottom: "1rem",
      textAlign: "center",
      [`&.disabled .${iconRef}`]: {
        backgroundColor: `${theme.colors.indigo[0]} !important`,
        color: `${theme.colors.indigo[6]} !important`,
      },
      [`&:hover .${iconRef}`]: {
        backgroundColor: theme.colors.indigo[6],
        color: theme.white,
      },
      [`&.active .${iconRef}`]: {
        backgroundColor: theme.colors.indigo[6],
        color: theme.white,
      },
      [`&::lastchild`]: {
        marginBottom: 0,
      },
    },
    icon: {
      ref: iconRef,
      transition: "background-color 150ms ease, transform 100ms ease",
      color: theme.colors.indigo[6],
      backgroundColor: theme.colors.indigo[0],
      borderRadius: 4,
      padding: 8,
    },
  };
});
