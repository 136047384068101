export const BARIMT_STATUS = {
  NEW: {
    code: "NEW",
    name: "Шинэ",
    color: "blue",
  },
  REGISTERED: {
    code: "REGISTERED",
    name: "Бүртгэгдсэн",
    color: "green",
  },
  FAILED: {
    code: "FAILED",
    name: "Амжилтгүй",
    color: "red",
  },
  RETURNED: {
    code: "RETURNED",
    name: "Буцаагдсан",
    color: "gray",
  },
  CANCELED: {
    code: "CANCELED",
    name: "Цуцлагдсан",
    color: "red",
  },
};

export const BARIMT_TYPE = {
  B2C_RECEIPT: {
    code: "B2C_RECEIPT",
    name: "Бизнес эрхлэгчээс хувь хүнд борлуулсан бүтээгдэхүүн, үйлчилгээний төлбөрийн баримт",
  },
  B2B_RECEIPT: {
    code: "B2B_RECEIPT",
    name: "Бизнес эрхлэгч хооронд борлуулсан бүтээгдэхүүн, үйлчилгээний төлбөрийн баримт",
  },
  B2C_INVOICE: {
    code: "B2C_INVOICE",
    name: "Бизнес эрхлэгчээс хувь хүнд борлуулсан бүтээгдэхүүн, үйлчилгээний нэхэмжлэх",
  },
  B2B_INVOICE: {
    code: "B2B_INVOICE",
    name: "Бизнес эрхлэгч хооронд борлуулсан бүтээгдэхүүн, үйлчилгээний нэхэмжлэх",
  },
};
